export default {
  namespaced: true,

  state: {
    module: "",
    expanded: false as boolean,
    mini: true as boolean
  },

  mutations: {
    show(state: any) {
      state.expanded = true;
    },
    hide(state: any) {
      state.expanded = false;
    },
    set(state: any, value: boolean) {
      state.expanded = value;
    },
    setModule(state: any, payload: string) {
      state.module = payload;
    },
    toggle(state: any) {
      state.expanded = !state.expanded;
    },
    toggleThemes(state: any) {
      state.mini = !state.mini;
    }
  },

  actions: {
    show(context: any) {
      context.commit("show");
    },
    set(context: any, value: boolean) {
      context.commit("set", value);
    },
    setModule(context: any, value: boolean) {
      context.commit("setModule", value);
    },
    hide(context: any) {
      context.commit("hide");
    },
    toggle(context: any) {
      context.commit("toggle");
    },
    toggleThemes(context: any) {
      context.commit("toggleThemes");
    }
  },

  getters: {
    expanded: (state: any): boolean => state.expanded,
    mini: (state: any): boolean => state.mini,
    currentModule: (state: any): boolean => state.module
  }
};
