import APIModel from "../APIModel";

export default class Clients extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "MPower";
  }

  async checkDocument(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 400, 500, 403)
      .get(`${this.resourceUrl}/user_has_privileges`, params);
  }
}
