import APIModel from "../APIModel";

export default class Banks extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "notifications";
  }

  async getData(params: object = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getList(params: object = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, params);
  }

  async getCount(params: object = {}): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/count`, params);
  }

  async setRead(id: string, params: any = {}): Promise<any> {
    return await this.http.post(`${this.resourceUrl}/${id}/read`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
