import store from "@/store";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpInterface } from "@/services/http";
import ErrorHandler from "@/services/http/error-handler";

class Interceptor {
  public static request(httpInstance: HttpInterface): void {
    httpInstance.getAxiosInstance().interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const queryConfig: AxiosRequestConfig = config;
        const credentials: CredentialsInterface =
          store.getters["authentication/credentials"];
        const currentModule = store.getters["navigation/currentModule"];
        const currentRole = store.getters["user/currentRole"];

        if (!httpInstance.getPreloaderState()) {
          await store.dispatch("preloader/show");
        }

        if (credentials.token) {
          queryConfig.headers.Authorization = `Bearer ${credentials.token}`;
        }

        if (currentModule) {
          queryConfig.headers["x-module"] = currentModule;
        }

        if (!queryConfig.headers["x-localization"]) {
          queryConfig.headers["x-localization"] =
            store.getters["localization/getCurrent"];
        }

        if (currentRole?.permission_id) {
          queryConfig.headers["x-permission"] = currentRole.permission_id;
        }

        return queryConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  public static response(httpInstance: HttpInterface): void {
    httpInstance.getAxiosInstance().interceptors.response.use(
      async (response: AxiosResponse) => {
        await store.dispatch("preloader/hide");
        return response;
      },
      async (error: AxiosError) => {
        const errorHandler = new ErrorHandler(
          httpInstance.getIgnoredErrors(),
          error.response?.request
        );
        await errorHandler.handle();

        await store.dispatch("preloader/hide");
        if (error.response && error.response.status >= 400) {
          return Promise.reject(error.response);
        }
        return Promise.resolve(error.response);
      }
    );
  }
}

export default Interceptor;
