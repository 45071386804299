import APIModel from "../APIModel";

export default class Users extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "users";
  }

  /* Get */

  async getOnline(): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/online`, {});
  }

  async checkUser(uuid: string): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .post(`login_by_uuid`, { uuid });
  }

  async checkUserByToken(params: any = {}): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .post(`login_by_token`, params);
  }

  async changeData(): Promise<any> {
    return "";
  }

  async getSpecialists(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list/specialists`, params);
  }

  async changeUserPassword(id: any, payload: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/reset_password`, payload);
  }

  async profile(userId?: number): Promise<any> {
    return await this.http.get(`users/${userId}/profile`);
  }

  async checkStatus(id: number): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/${id}/read`, {});
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: string): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getClient(id: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404)
      .get(`${this.resourceUrl}/${id}/client`, {});
  }

  async liveSearchBeneficiary(params: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500, 401, 403, 422)
      .get(`${this.resourceUrl}/live_search_for_beneficiaries`, params);
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }
  async getEmployeesList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list/employees`, params);
  }

  async getBeneficiariesList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list/beneficiaries`, params);
  }

  async getSpecialistsByOrder(order_id: number, params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list/${order_id}/by_order`, params);
  }

  async show(id: number, params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}/show`, params);
  }

  async edit(id: string, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .delete(`${this.resourceUrl}`, { id });
  }

  async changePassword(id: any, password: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/reset_password`, {
        password
      });
  }

  async login(endpoint: string, payload: object) {
    return await this.http.post(endpoint, payload);
  }

  async loginAs(id: number) {
    return await this.http.get(`login_as/${id}`);
  }

  async getProfile(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404, 422)
      .get(`${this.resourceUrl}/beneficiary/profile`, params);
  }

  async updateProfile(payload = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404, 422)
      .patch(`${this.resourceUrl}/beneficiary/profile`, payload);
  }
}
