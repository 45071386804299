import APIModel from "../APIModel";

export default class Banks extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "exchange_rates";
  }

  /* Get */

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getRates(date: string): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${date}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async getExchangeRate(currency_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404, 422)
      .get(`exchange_rates/today_exchange_for`, { currency_id });
  }

  async getDataCurrent(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(403, 404, 422, 500)
      .get(`${this.resourceUrl}/now`, {});
  }

  /* Post */

  async edit(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .post(`${this.resourceUrl}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
