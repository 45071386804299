import APIModel from "../APIModel";
import store from "@/store";

export default class PackingCategories extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "packing_categories";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(headers: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {}, headers);
  }

  async liveSearch(query: string): Promise<any> {
    //todo убрать эти параметры параметры, сделать нормально на бэке
    //todo фильтры указывать в компоненте где вызывается апи
    const lang = store.getters["localization/getCurrent"];
    const params = {
      fields: {
        value: "id",
        text: `name_${lang}`
      },
      route: "v1.packing_categories.index.get",
      filter: {
        [`name_${lang}`]: query
      }
    };
    return await this.http.get(`${this.resourceUrl}/live_search`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
