
import Vue from "vue";

export default Vue.extend({
  name: "AppLayout",

  components: {
    default: () =>
      import(/* webpackChunkName: "default" */ `@/layouts/default.vue`),
    auth: () => import(/* webpackChunkName: "auth" */ `@/layouts/auth.vue`)
  },

  computed: {
    layout(): any {
      return this.$options?.components?.[
        this.$route?.meta?.layout || "default"
      ];
    }
  }
});
