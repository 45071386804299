import APIModel from "../APIModel";

export default class EmailTemplates extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "document_templates";
  }

  /* GET */

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getVariables(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/variables`, {});
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(service_type_id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, { service_type_id });
  }

  async show(id: number, order_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 404, 400, 500)
      .get(`${this.resourceUrl}/${id}/show`, { order_id });
  }

  async getUsers(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  /* POST */

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async sendEmail(
    userId: number,
    templateId: number | null,
    payload: object
  ): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .get(
        `notification_templates/${userId}/${templateId}/send_email`,
        payload
      );
  }

  /* PATCH */
}
