const meta = {
  module: "invoices"
};

export default [
  {
    path: `/invoices`,
    redirect: "/invoices/page/1"
  },
  {
    path: `/invoices/page`,
    redirect: "/invoices/page/1"
  },
  {
    path: `/invoices/page/:page`,
    name: "invoices",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/modules/invoices/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
