import store from "@/store";

export default {
  inserted(el: any, bindings: any) {
    el.style.cursor = "pointer";
    el.setAttribute("title", "Копировать текст");
    el.handleEvent = (e: any) => {
      e.stopPropagation();
      const textarea = document.createElement("textarea");
      textarea.value = bindings.value;
      el.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      store.dispatch("alert/showInfo", "Текст успешно спокирован!");
      el.removeChild(textarea);
    };

    el.addEventListener("click", el.handleEvent);
  }
};
