export default {
  main_manager: [
    {
      action: ["show"],
      subject: ["SideBar"]
    }
  ],
  admin: [
    {
      action: ["show"],
      subject: ["SideBar"]
    }
  ],
  manager: [
    {
      action: ["show"],
      subject: ["SideBar"]
    }
  ],
  beneficiary: [
    {
      action: ["show"],
      subject: ["SideBar"]
    }
  ]
};
