import APIModel from "../APIModel";

export default class GoodsCategories extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "goods_categories";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async getParentsList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/parents`, {});
  }

  async getChildrenList(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/children`, {});
  }

  async liveSearch(query: string): Promise<any> {
    //todo убрать эти параметры параметры, сделать нормально на бэке
    //todo фильтры указывать в компоненте где вызывается апи
    const params = {
      fields: {
        value: "id",
        text: "name_ru"
      },
      route: "v1.goods.index.get",
      filter: {
        name_ru: query
      }
    };
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async checkExistent(key: string, value: string): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/check_unique/${key}/${value}`
    );
  }
}
