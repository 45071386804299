import { AxiosResponse } from "axios";
import APIModel from "../APIModel";

export default class Auth extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "oauth";
  }

  async login(payload: object): Promise<AxiosResponse> {
    return await this.http.ignoreErrorHandler().post(`/login`, payload);
  }

  async forgot(payload: object): Promise<AxiosResponse> {
    return await this.http
      .ignoreErrorHandler(404)
      .ignoreGlobalPreloader()
      .post(`auth/forgot-password`, payload);
  }

  async registerClient(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .useFormData()
      .post(`${this.resourceUrl}/registration`, payload);
  }

  async resetPassword(payload: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .ignoreGlobalPreloader()
      .useFormData()
      .post(`auth/reset-password`, payload);
  }

  async activate(token: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/activate/${token}`, {});
  }

  async checkToken(token: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(`auth/check-reset-token/${token}`, {});
  }

  async changeSecurity(payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler()
      .post(`profile/update_settings`, payload);
  }
}
