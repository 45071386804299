
import Vue from "vue";

export default Vue.extend({
  name: "FullscreenDialog",

  props: {
    src: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showed: false as boolean
  }),

  methods: {
    open(): void {
      this.showed = true;
    },
    close(): void {
      this.showed = false;
    }
  }
});
