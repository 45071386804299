const meta = {
  module: "auth",
  layout: "auth",
  withoutCredentials: true
};

export default [
  {
    path: `/auth/login`,
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/auth/views/login.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-confirmed/:token",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-confirmed-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-confirmed",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-confirmed-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-expired",
    name: "email-expired",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-expired-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/auth/reset/:token`,
    name: "reset",
    component: () =>
      import(/* webpackChunkName: "Reset" */ "@/modules/auth/views/reset.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-sent/:email",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-sent-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/auth/register`,
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "Register" */ "@/modules/auth/views/register.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/reset-expired",
    name: "reset-expired",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/reset-expired.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/password-changed",
    name: "password-changed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/password-changed.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/success-reset",
    name: "success-reset",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/success-reset.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/password-extension",
    name: "password-extension",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/password-extension.vue"
      ),
    meta: {
      ...meta
    }
  }
];
