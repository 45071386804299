const meta = {
  module: "home"
};

export default [
  {
    path: `/home`,
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/modules/orders/views/create.vue"
      ),
    meta: {
      ...meta,
      withoutWrapperPadding: true
    }
  }
];
