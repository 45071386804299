import APIModel from "../APIModel";

export default class Goods extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "goods";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async liveSearch(params: any = {}, headers: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search_for_service_type`, params, headers);
  }

  async liveSearchCode(params: any = {}, headers: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search/exp`, params, headers);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async checkExistent(key: string, value: string): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/check_unique/${key}/${value}`
    );
  }
}
