import APIModel from "../APIModel";

export default class Guarantees extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "order_guarantees";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async getPaymentTypes(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404)
      .get(`${this.resourceUrl}/types`, {});
  }

  async getOrderGuarantees(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`orders/${id}/guarantees`, {});
  }

  async getStatuses(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/statuses`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async unlinkFromOrder(id: number, payload: object = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 404, 500, 405)
      .useFormData()
      .post(`${this.resourceUrl}/${id}/unlink?_method=PATCH`, payload);
  }

  async getBeneficiaryGuarantees(
    beneficiary_id: number,
    payload: object = {}
  ): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 404, 500, 405)
      .get(`${this.resourceUrl}/${beneficiary_id}/free`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async addExistingGuaranteeToOrder(
    beneficiary_id: number,
    payload = {}
  ): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 404, 500, 405)
      .useFormData()
      .post(
        `${this.resourceUrl}/${beneficiary_id}/link?_method=PATCH`,
        payload
      );
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
