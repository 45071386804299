import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import themes from "@/themes/default/colors";
import store from "@/store";
import en from "vuetify/src/locale/en";
import ru from "vuetify/src/locale/ru";
import ro from "vuetify/src/locale/ro";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, ru, ro },
    current: store.getters["localization/getCurrent"]
  },
  theme: {
    options: {
      customProperties: true
    },
    themes
  }
});
