export default {
  namespaced: true,

  state: {
    show: false as boolean,
    showGlobal: true as boolean
  },

  mutations: {
    show(state: any) {
      state.show = true;
    },
    hide(state: any) {
      state.show = false;
    },
    showGlobal(state: any) {
      state.showGlobal = true;
    },
    hideGlobal(state: any) {
      state.showGlobal = false;
    }
  },

  actions: {
    show(context: any) {
      context.commit("show");
    },
    hide(context: any) {
      context.commit("hide");
    },
    showGlobal({ commit }: any) {
      commit("showGlobal");
    },
    hideGlobal({ commit }: any) {
      commit("hideGlobal");
    }
  },

  getters: {
    show: (state: any): boolean => state.show,
    showGlobal: (state: any): boolean => state.showGlobal
  }
};
