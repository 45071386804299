
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",

  props: {
    executeAction: {
      type: Function,
      required: true
    },
    isShowConfirm: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ""
    }
  },

  data: () => ({
    showed: false as boolean,
    action: "" as string,
    title: "" as string,
    text: "" as string,
    sheet: false as boolean,
    params: {} as any
  }),

  watch: {
    isShowConfirm: {
      immediate: true,
      handler() {
        this.showed = this.isShowConfirm;
      }
    },
    label: {
      immediate: true,
      handler() {
        this.text = this.label;
      }
    }
  },

  methods: {
    open(action: string, title: string, text: string, params: any): void {
      this.action = action;
      this.title = title;
      this.text = text;
      this.params = params;
      this.showed = true;
    },
    close(): void {
      this.showed = false;
    },
    confirm(): void {
      this.executeAction(this.action, this.params);
      this.close();
    },
    cancel(): void {
      this.$emit("cancel");
      this.close();
    }
  }
});
