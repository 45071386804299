export default {
  beneficiary: [
    // {
    //   action: ["create"],
    //   subject: ["Chat:room"]
    // },
    {
      action: [
        //"showGroups",
        "show"
      ],
      subject: ["Chat:rooms"]
    },
    {
      action: ["show"],
      subject: ["Chat"]
    }
  ],
  manager: [
    // {
    //   action: ["create"],
    //   subject: ["Chat:room"]
    // },
    {
      action: [
        //"showGroups",
        "show"
      ],
      subject: ["Chat:rooms"]
    },
    {
      action: ["show"],
      subject: ["Chat"]
    }
  ],
  admin: [
    {
      action: ["create"],
      subject: ["Chat:room"]
    },
    {
      action: ["show", "showGroups"],
      subject: ["Chat:rooms"]
    },
    {
      action: ["show"],
      subject: ["Chat"]
    }
  ],
  guest: [
    {
      action: ["openFirst"],
      subject: ["Chat:rooms"]
    }
  ]
};
