import APIModel from "../APIModel";

export default class Countries extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "countries";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(headers: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 403, 500, 401, 405)
      .setHeaders(headers)
      .get(`${this.resourceUrl}/list`, {});
  }

  async liveSearch(params: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search`, params);
  }

  async getAcceptAta(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list/accept_ata`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
