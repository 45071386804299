import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import RouterMiddleware from "./middleware";

const routes: Array<RouteConfig> = [];
const modules = require.context("./modules", true, /[A-Za-z0-9-_,\s]+\.ts$/i);

modules.keys().forEach(key => {
  routes.push(...modules(key).default);
});

Vue.use(VueRouter);

store.dispatch("localization/setFromRouter");

const router = new VueRouter({
  mode: "history",
  base: store.getters["localization/getCurrent"],
  routes
});

const middleware = new RouterMiddleware(router);

middleware.apply();

export default router;
