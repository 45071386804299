const meta = {
  module: "help"
};

export default [
  {
    path: `/help`,
    redirect: "/help/page/1"
  },
  {
    path: `/help/page`,
    redirect: "/help/page/1"
  },
  {
    path: `/help/page/:page`,
    name: "help",
    component: () =>
      import(/* webpackChunkName: "help" */ "@/modules/help/views/index.vue"),
    meta: {
      ...meta
    }
  }
];
