import APIModel from "../APIModel";

export default class Profile extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "profile";
  }

  /* GET */

  async getWorkPositionsList(): Promise<any> {
    return await this.http.get(`work_positions/list`, {});
  }

  async getDepartmentsList(): Promise<any> {
    return await this.http.get(`subdivisions/list`, {});
  }

  async getBranchesList(): Promise<any> {
    return await this.http.get(`branches/list`, {});
  }

  /* POST */

  /* PATCH */

  async changePassword(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`users/${id}/change_password`, payload);
  }

  async resetPassword(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`oauth/password_reset`, payload);
  }

  async changeAvatar(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`users/${id}?_method=PATCH`, payload);
  }

  async updateProfile(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`users/${id}/profile/update`, payload);
  }
}
