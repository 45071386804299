import APIModel from "../APIModel";

export default class Goods extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "order_payments";
  }

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getAuthors(): Promise<any> {
    return await this.http.get(`users/list`, {});
  }

  async getPayments(): Promise<any> {
    return await this.http.get(`payment_methods/list`, {});
  }

  async getOrderPayments(order_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 422, 500)
      .get(`${this.resourceUrl}/${order_id}/list`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 500)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async getPaymentLink(invoice_id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404, 401, 403, 422)
      .get(`mpay/get_payment_link`, { invoice_id });
  }
}
