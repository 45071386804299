const meta = {
  module: "payments"
};

export default [
  {
    path: `/payments`,
    redirect: "/payments/page/1"
  },
  {
    path: `/payments/page`,
    redirect: "/payments/page/1"
  },
  {
    path: `/payments/page/:page`,
    name: "payments",
    component: () =>
      import(
        /* webpackChunkName: "payments" */ "@/modules/payments/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
