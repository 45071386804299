const meta = {
  module: "notifications"
};

export default [
  {
    path: `/notifications`,
    redirect: "/notifications/page/1"
  },
  {
    path: `/notifications/page`,
    redirect: "/notifications/page/1"
  },
  {
    path: `/notifications/page/:page`,
    name: "notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/modules/notifications/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/notifications/create`,
    name: "notifications/create",
    component: () =>
      import(
        /* webpackChunkName: "notifications/create" */ "@/modules/notifications/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  }
];
