import { Ability } from "@casl/ability";

function subjectName(item: any) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

//@ts-ignore
const ability = new Ability(
  [],
  //@ts-ignore
  { subjectName }
);
export default ability;
